import { BrowserRouter } from 'react-router-dom'
import styled from 'styled-components'
import Counter from './components/counter/Counter'
import GlobalContext from './GlobalContext'

const Container = styled.div`
  width: 100vw;
  height: 100vh;
`

const App = () => {
  return (
    <BrowserRouter>
      <GlobalContext>
        <Container>
          <Counter />
        </Container>
      </GlobalContext>
    </BrowserRouter>
  )
}

export default App
