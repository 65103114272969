import { CSSProperties } from 'react'
import { Container } from './Styles'

const Button = (props: {
  children: React.ReactNode
  onClick: () => void
  style?: CSSProperties
}) => {
  return (
    <Container onClick={props.onClick} style={props.style}>
      {props.children}
    </Container>
  )
}

export default Button
