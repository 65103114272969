import { useNavigate } from 'react-router-dom'
import Button from '../button/Button'
import { Container, Content, CountBox, Title } from './Styles'
import useCounter from './use-counter'
import Table from './Table'

const Count = () => {
  const { total, counter } = useCounter()
  const navigate = useNavigate()

  return (
    <Content>
      <Title>Ägg</Title>
      <Container>
        <CountBox>{total} st</CountBox>
        <Button onClick={() => navigate('/add')}>Lägg till</Button>
      </Container>
      {counter && <Table counter={counter} />}
    </Content>
  )
}

export default Count
