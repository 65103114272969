import { Route, Routes, useNavigate } from 'react-router-dom'
import Add from './Add'
import Count from './Count'

const Counter = () => {
  return (
    <Routes>
      <Route path='/' element={<Count />} />
      <Route path={'/add'} element={<Add />} />
    </Routes>
  )
}

export default Counter
