import axios from 'axios'
import { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { Count } from './types'

const useCounter = () => {
  const [counter, setCounter] = useState<Count[]>()
  const [total, setTotal] = useState<number>(0)
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const add = async (quantity: number, date?: string) => {
    const result = await axios.post('/api/add/1', {
      quantity,
      date,
    })

    if (result) {
      queryClient.invalidateQueries('counter')
      navigate('/')
    }
  }

  const { data } = useQuery<{ total: { total: number }; count: Count[] }>(
    'counter',
    async () => (await axios.get('/api/counter/1')).data
  )

  useEffect(() => {
    if (!data) {
      return
    }

    setTotal(data.total.total)
    setCounter(data.count)
  }, [data])

  return {
    counter,
    total,
    add,
  }
}

export default useCounter
