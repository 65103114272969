import useCounter from './use-counter'
import { Container, Content, Title } from './Styles'
import { useNavigate } from 'react-router-dom'
import Button from '../button/Button'
import { useState } from 'react'
import Input from '../input/Input'

const Add = () => {
  const { add } = useCounter()
  const navigate = useNavigate()
  const [quantity, setQuantity] = useState<number>(0)
  const [date, setDate] = useState<string>(
    new Date().toLocaleDateString('sv-se', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    })
  )

  return (
    <Content>
      <Title>Lägg ägg</Title>
      <Container>
        <Input
          placeholder='Antal'
          onChange={(s: string) => setQuantity(Number(s))}
          type='number'
          min={0}
          max={50}
          style={{ margin: '0 0 20px 0' }}
        />
        <Input
          value={date}
          placeholder='Datum'
          onChange={(s: string) => setDate(s)}
          type='date'
        />
      </Container>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '20px 0 0 0',
        }}
      >
        <Button onClick={() => navigate('/')}>Back</Button>
        <Button onClick={() => add(quantity, date)}>Save</Button>
      </div>
    </Content>
  )
}

export default Add
