import { ChangeEvent, CSSProperties } from 'react'
import { StyledInput } from './Styles'

const Input = (props: {
  value?: string
  onChange: (s: string) => void
  placeholder: string
  style?: CSSProperties
  type?: 'number' | 'text' | 'date'
  min?: number
  max?: number
}) => {
  return (
    <StyledInput
      value={props.value}
      placeholder={props.placeholder}
      onChange={(e: ChangeEvent<HTMLInputElement>) =>
        props.onChange(e.currentTarget.value)
      }
      type={props.type ? props.type : 'text'}
      min={props.min}
      max={props.max}
      style={props.style}
    />
  )
}

export default Input
