import styled from 'styled-components'

export const StyledInput = styled.input`
  width: calc(100% - 10px);
  height: 32px;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  padding: 0 5px;
  font-size: 18px;

  :focus {
    outline: none;
  }
`
