import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2a5a95;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  height: 32px;
  padding: 0 10px;
  font-weight: bold;

  :hover {
    background: #538ed7;
  }
`
