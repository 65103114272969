import { Count } from './types'
import { StyledTable, Tr, Td, Thead } from './Styles'
import { getDay } from './utils'

const Table = (props: { counter: Count[] }) => {
  return (
    <StyledTable style={{ margin: '20px 0 0 0' }}>
      <Thead>
        <Td>Dag</Td>
        <Td>Datum</Td>
        <Td>Antal</Td>
      </Thead>
      {props.counter.map((c, i) => {
        const day = new Date(c.date)
        const weekday = day.getDay()

        return (
          <Tr key={i}>
            <Td>{getDay(weekday)}</Td>
            <Td>
              {day.toLocaleDateString('sv-se', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              })}
            </Td>
            <Td>{c.quantity}</Td>
          </Tr>
        )
      })}
    </StyledTable>
  )
}

export default Table
