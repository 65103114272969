import styled from 'styled-components'

export const Content = styled.div`
  width: 300px;
  margin: 0 auto;
  padding: 10px 0 0 0;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const CountBox = styled.div`
  height: 32px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  font-weight: bold;
  color: #000000;
  border-radius: 5px;
  background: #fff;
  margin: 0 0 10px 0;
`

export const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  margin: 0 0 10px 0;
  display: flex;
  justify-content: center;
  color: #222;
`

export const StyledTable = styled.div`
  display: table;
  background: #fff;
  width: 100%;
`

export const Thead = styled.div`
  display: table-row;
  background: #e4e4e4;
`

export const Tr = styled.div`
  display: table-row;
`

export const Td = styled.div`
  display: table-cell;
  padding: 4px 7px;
  border-bottom: 1px solid #e4e4e4;
`
