import { createContext } from 'react'

interface Context {
  darkMode: boolean
}

export const Context = createContext<Context>({ darkMode: false })

const GlobalContext = (props: { children: React.ReactNode }) => {
  let darkMode = false
  try {
    darkMode = Boolean(document.URL.split('?')[1].split('=')[1])
  } catch (e) {
    // Ignore
  }

  return (
    <Context.Provider value={{ darkMode }}>{props.children}</Context.Provider>
  )
}

export default GlobalContext
